import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/Default"

import Header from "../components/common/Header"
import Card from "../components/common/Card"
import Reviews from "../components/Reviews"

export default function Page({ data, location }) {
  return (
    <Layout
      title="Infocenter zum Thema Finanzen"
      description="Entdecke unser Infocenter. Wir möchten Dich auf dem Laufenden halten und beantworten Deine Fragen im Blog oder im Podcast."
      location={location}
      imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
      imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
      imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
      imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
    >
      <Header
        image={data.header}
        link="#medien"
        linktext="Medienauswahl"
        link2="/ueber-uns/"
        link2text="Über uns"
        alt="Bücherstapell"
      >
        <h1 className="mt-1 mb-8 text-2xl font-extrabold leading-relaxed text-gray-900 sm:text-3xl lg:text-4xl">
          Infocenter zum Thema <span className="text-blue-500">Finanzen</span>
        </h1>
        <p className="text-lg leading-7 text-gray-700 ">
          Entdecke unser Infocenter. Wir möchten Dich auf dem Laufenden halten
          und beantworten Deine Fragen im Blog oder im Podcast.
        </p>
      </Header>
      <div className="container mx-auto my-6" id="medien">
        <div className="relative px-4 pt-16 pb-20 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Medienauswahl
              </h2>
              <p className="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-700 sm:mt-4">
                Egal ob Du gerne liest oder einfach nur hören willst. Unten
                findest Du eine Auswahl unserer Medien.
              </p>
            </div>
            <div className="grid gap-5 mx-auto mt-12 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
              {/* <Card
                image={data.blog}
                title="Blog"
                link="/info/blog/"
                text="Unsere schriftliche Wissensdatenbank um Dich finanziell voran zu bringen."
              /> */}

              <Card
                image={data.faq}
                title="FAQ"
                link="/info/faq/"
                text="Wir sammeln und beantworten in diesem Bereich Deine häufigsten Fragen."
              />
            </div>
          </div>
        </div>
      </div>
      <Reviews />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    
    header: file(relativePath: { eq: "pages/info/header.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }

    seoImage: file(
      relativePath: { eq: "headers/dierda_startseite_header.jpg" }
    ) {
      facebook: childImageSharp {
        gatsbyImageData(height: 630, width: 1200)
      }
      twitter: childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }


    blog: file(relativePath: { eq: "pages/info/blog.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.5)
      }
    }
    podcast: file(relativePath: { eq: "pages/info/podcast.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.5)

      }
    }
    faq: file(relativePath: { eq: "pages/info/faq.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.5)

      }
    }
  }
`
